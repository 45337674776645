import { createSlice } from '@reduxjs/toolkit'

export const files = createSlice({
  name: "files",
  initialState: {
      files: []
  },
  reducers: {
    updateFiles: (state, action) => {
        return {
          files: action.payload
        }
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = files
// Extract and export each action creator by name
export const { updateFiles } = actions
// Export the reducer, either as a default or named export
export default reducer
